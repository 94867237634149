export default [
  {
    title: 'My Accounts',
    route: 'my-accounts',
    icon: 'FolderIcon',
  },
  {
    title: 'Charges',
    route: 'charges',
    icon: 'DollarSignIcon',
  },
]
